import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Header from './header';
import Footer from './footer';

import './layout.css';

const Main = styled.main`
  margin: 0 auto 0 auto;
  padding-top: 126px;

  @media only screen and (max-width: 620px) {
    padding-top: 65px;
  }
`;

const Layout = React.memo(({ children }) => (
  <div>
    <Header />
    <Main className="main">{children}</Main>
    <Footer />
  </div>
));

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
