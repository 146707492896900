import React from 'react';
import styled from 'styled-components';

import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Layout } from 'antd';
import { FcCellPhone, FcFeedback } from 'react-icons/fc';

// styled-components
const { Footer } = Layout;

const FooterStyled = styled(Footer)`
  background-color: #262626;
  color: #999;
  padding: 40px 0;

  @media only screen and (max-width: 568px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const DContainer = styled.div`
  max-width: 90%;
  width: 980px;
  margin: 0 auto;

  @media only screen and (max-width: 568px) {
    max-width: 85%;
  }
`;

const FooterTable = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
    'info info info'
    'logo signature signature';
  gap: 100px 0px;

  @media only screen and (max-width: 568px) {
    grid-template-areas:
      'info info info'
      'logo logo logo'
      'signature signature signature';
    gap: 30px 0px;
  }
`;

const FooterText = styled.div`
  grid-area: info;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 0 10px;

  @media only screen and (max-width: 568px) {
    grid-template-columns: auto;
    grid-template-rows: 0.7fr 0.7fr 1fr;
    gap: 10px 0px;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterLogo = styled.div`
  grid-area: logo;

  @media only screen and (max-width: 568px) {
    text-align: center;
  }
`;

const FooterSignature = styled.div`
  grid-area: signature;
  text-align: center;

  @media only screen and (max-width: 568px) {
    text-align: center;
  }
`;

const FooterLink = styled(Link)`
  font-size: 1.1em;

  &:hover {
    color: #ffffff !important;
    text-decoration: underline;
  }

  @media only screen and (max-width: 568px) {
    font-size: 1.2em;
    margin-top: 9px;
  }
`;

const LineSto = styled.span`
  border: 1px solid rgb(255, 215, 0);
  margin: 0 5px;
`;

const FooterWidget = styled.div`
  display: flex;
  padding-bottom: 1em;
  align-items: center;
  font-size: 1.1em;
  color: #7f7f7f;
  text-transform: uppercase;
  font-weight: 600;
`;

const ContactSeparator = styled.div`
  margin: 3em 0;
  border: 1px solid #dddddd;
  background-color: #dddddd;
`;

const FooterLogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MyFooter = React.memo(() => {
  const data = useStaticQuery(graphql`
    {
      colombia: file(relativePath: { eq: "logos/Logo-Colombia.png" }) {
        childImageSharp {
          gatsbyImageData(width: 32, layout: FIXED)
        }
      }
      linkedin: file(relativePath: { eq: "logos/Logo-Linkedin.png" }) {
        childImageSharp {
          gatsbyImageData(width: 27, layout: FIXED)
        }
      }
      instagram: file(relativePath: { eq: "logos/Logo-Instagram.png" }) {
        childImageSharp {
          gatsbyImageData(width: 27, layout: FIXED)
        }
      }
      youtube: file(relativePath: { eq: "logos/Logo-Youtube.png" }) {
        childImageSharp {
          gatsbyImageData(width: 27, layout: FIXED)
        }
      }
      facebook: file(relativePath: { eq: "logos/Logo-Facebook.png" }) {
        childImageSharp {
          gatsbyImageData(width: 27, layout: FIXED)
        }
      }
    }
  `);

  return (
    <FooterStyled>
      <DContainer>
        <FooterTable>
          <FooterText>
            <FooterColumn>
              <FooterLink to="/">Inicio</FooterLink>
              <FooterLink to="/centro-de-documentos">Centro de Documentos</FooterLink>
            </FooterColumn>
            <FooterColumn>
              <FooterLink to="/contacto">Contacto</FooterLink>
              <FooterLink to="/showroom">Showroom</FooterLink>
              <FooterLink to="/politica-privacidad">Política de Privacidad</FooterLink>
            </FooterColumn>
            <FooterColumn>
              <FooterWidget>
                <FcCellPhone
                  style={{
                    fontSize: '30px',
                    color: '#fad900',
                    marginRight: '0.5em',
                  }}
                />
                <span>(031) 745 12 80</span>
              </FooterWidget>
              <FooterWidget>
                <FcFeedback
                  style={{
                    fontSize: '30px',
                    color: '#fad900',
                    marginRight: '0.5em',
                  }}
                />
                <Link to="/contacto" style={{ textTransform: 'capitalize' }}>
                  Contactanos
                </Link>
              </FooterWidget>
              <ContactSeparator />
              <span style={{ marginBottom: '1em' }}>Siguenos</span>
              <FooterLogoContainer>
                <a
                  href="https://www.linkedin.com/company/sto-colombia/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'contents' }}
                >
                  <GatsbyImage
                    image={data.linkedin.childImageSharp.gatsbyImageData}
                    style={{ marginRight: '0.5rem' }}
                    alt="logo-linkedin"
                  />
                </a>
                <a
                  href="https://www.instagram.com/sto.colombia/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'contents' }}
                >
                  <GatsbyImage
                    image={data.instagram.childImageSharp.gatsbyImageData}
                    style={{ marginRight: '0.5rem' }}
                    alt="logo-instagram"
                  />
                </a>
                <a
                  href="https://www.facebook.com/Stocolombia"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'contents' }}
                >
                  <GatsbyImage
                    image={data.facebook.childImageSharp.gatsbyImageData}
                    style={{ marginRight: '0.5rem' }}
                    alt="logo-facebook"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UC4LEo6FoVFYmY28vAuxDLdA"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'contents' }}
                >
                  <GatsbyImage
                    image={data.youtube.childImageSharp.gatsbyImageData}
                    style={{ marginRight: '0.5rem' }}
                    alt="logo-youtube"
                  />
                </a>
              </FooterLogoContainer>
            </FooterColumn>
          </FooterText>
          <FooterLogo>
            <span style={{ fontWeight: '700' }}>Sto</span>
            <LineSto />
            <span>Construir a conciencia</span>
          </FooterLogo>
          <FooterSignature>
            <span>© Sto Colombia - Derechos Reservados - {new Date().getFullYear()}</span>
          </FooterSignature>
        </FooterTable>
      </DContainer>
    </FooterStyled>
  );
});

export default MyFooter;
