import React from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Layout, Menu, Select } from 'antd';
import { MdMenu } from 'react-icons/md';

// styled-components
const HeaderTop = styled.div`
  height: 60px;
  padding: 0 30px 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;

  @media only screen and (max-width: 620px) {
    display: none;
  }
`;

const HeaderTopLinks = styled.div`
  display: flex;
  align-items: center;
  color: #666666;
  font-size: 14px;
`;

const HeaderTopSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const HeaderBottom = styled.div`
  -webkit-box-shadow: 0px 4px 4px 0px rgba(173, 173, 173, 0.74);
  -moz-box-shadow: 0px 4px 4px 0px rgba(173, 173, 173, 0.74);
  box-shadow: 0px 4px 4px 0px rgba(173, 173, 173, 0.74);
  background-color: #fff;
  padding: 0 30px 0 30px;
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: 1fr;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 20% 80%;
  }
`;

const HeaderLink = styled(Link)`
  &:hover {
    color: #333333;
    text-decoration: underline;
  }
`;

const HeaderLogo = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2em;
`;

const HeaderLogoA = styled.a`
  display: inline-flex;
  align-items: center;
`;

const { Header } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;

const values = {
  STO_ARG: 'sto_arg',
  STO_BRA: 'sto_bra',
  STO_CH: 'sto_ch',
  STO_CORP: 'sto_corp',
  STO_CO: 'sto_co',
  STO_PA: 'sto_pa',
  STO_ECU: 'sto_ecu',
  STO_CORI: 'sto_cori',
};

const MyHeader = React.memo(() => {
  const data = useStaticQuery(graphql`
    {
      sto: file(relativePath: { eq: "logos/Logo-Sto.png" }) {
        childImageSharp {
          gatsbyImageData(width: 200, quality: 100, layout: FIXED)
        }
      }
      colombia: file(relativePath: { eq: "logos/Logo-Colombia.png" }) {
        childImageSharp {
          gatsbyImageData(width: 32, layout: FIXED)
        }
      }
      linkedin: file(relativePath: { eq: "logos/Logo-Linkedin.png" }) {
        childImageSharp {
          gatsbyImageData(width: 27, layout: FIXED)
        }
      }
      instagram: file(relativePath: { eq: "logos/Logo-Instagram.png" }) {
        childImageSharp {
          gatsbyImageData(width: 27, layout: FIXED)
        }
      }
      youtube: file(relativePath: { eq: "logos/Logo-Youtube.png" }) {
        childImageSharp {
          gatsbyImageData(width: 27, layout: FIXED)
        }
      }
      facebook: file(relativePath: { eq: "logos/Logo-Facebook.png" }) {
        childImageSharp {
          gatsbyImageData(width: 27, layout: FIXED)
        }
      }
    }
  `);

  const handleChange = (value) => {
    switch (value) {
      case values.STO_ARG:
        window.open('https://stochile.com/', '_blank');
        break;
      case values.STO_CORP:
        window.open('https://www.stocorp.com/', '_blank');
        break;
      case values.STO_BRA:
        window.open('https://stobrasil.com.br/', '_blank');
        break;
      case values.STO_CH:
        window.open('https://stochile.com/', '_blank');
        break;
      default:
        window.open('https://www.stocolombia.com/', '_blank');
        break;
    }
  };

  return (
    <nav>
      <Header
        style={{
          padding: '0 0',
          height: 'auto',
          backgroundColor: '#eeeeee',
          position: 'fixed',
          zIndex: 1,
          width: '100%',
        }}
      >
        <HeaderTop>
          <HeaderTopLinks>
            <GatsbyImage
              image={data.colombia.childImageSharp.gatsbyImageData}
              style={{ marginRight: '1rem' }}
              alt="logo-colombia"
            />
            <span style={{ marginRight: '0.5rem' }}>|</span>
            <HeaderLink style={{ marginRight: '0.5rem' }} to="/centro-de-documentos">
              Encuentra Documentos
            </HeaderLink>
            <span style={{ marginRight: '0.5rem' }}>|</span>
            <HeaderLink style={{ marginRight: '0.5rem' }} to="/showroom">
              Nuestro Showroom
            </HeaderLink>
            <span style={{ marginRight: '0.5rem' }}>|</span>
          </HeaderTopLinks>
          <HeaderTopSearch>
            <Select
              defaultValue="Seleccciona ubicación"
              onChange={handleChange}
              size="small"
              style={{ fontSize: '12px', marginRight: '1rem' }}
            >
              <Option value={values.STO_ARG}>ARGENTINA</Option>
              <Option value={values.STO_BRA}>BRASIL</Option>
              <Option value={values.STO_CH}>CHILE</Option>
              <Option value={values.STO_CO}>COLOMBIA</Option>
              <Option value={values.STO_ECU}>ECUADOR</Option>
              <Option value={values.STO_PA}>PANAMA</Option>
              <Option value={values.STO_CORP}>STO CORP</Option>
            </Select>
            <a
              href="https://www.linkedin.com/company/sto-colombia/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'contents' }}
            >
              <GatsbyImage
                image={data.linkedin.childImageSharp.gatsbyImageData}
                style={{ marginRight: '0.5rem' }}
                alt="logo-linkedin"
              />
            </a>
            <a
              href="https://www.instagram.com/sto.colombia/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'contents' }}
            >
              <GatsbyImage
                image={data.instagram.childImageSharp.gatsbyImageData}
                style={{ marginRight: '0.5rem' }}
                alt="logo-instagram"
              />
            </a>
            <a
              href="https://www.facebook.com/Stocolombia"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'contents' }}
            >
              <GatsbyImage
                image={data.facebook.childImageSharp.gatsbyImageData}
                style={{ marginRight: '0.5rem' }}
                alt="logo-facebook"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UC4LEo6FoVFYmY28vAuxDLdA"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'contents' }}
            >
              <GatsbyImage
                image={data.youtube.childImageSharp.gatsbyImageData}
                style={{ marginRight: '0.5rem' }}
                alt="logo-youtube"
              />
            </a>
          </HeaderTopSearch>
        </HeaderTop>
        <HeaderBottom>
          <Menu
            theme="ligth"
            mode="horizontal"
            style={{ border: 'none', width: '100%' }}
            overflowedIndicator={<MdMenu />}
          >
            <SubMenu
              key="memu-0"
              title={
                <span>
                  <b>NOSOTROS</b>
                </span>
              }
            >
              <Menu.Item key="nosotros-0" style={{ textTransform: 'uppercase' }}>
                <Link to="/quienes-somos">Quienes Somos</Link>
              </Menu.Item>
              <Menu.Item key="nosotros-1" style={{ textTransform: 'uppercase' }}>
                <Link to="/historia">Nuestra Historia</Link>
              </Menu.Item>
              <Menu.Item key="nosotros-2" style={{ textTransform: 'uppercase' }}>
                <Link to="/compromiso-de-marca">Nuestro Compromiso</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="memu-1"
              title={
                <span>
                  <b>SOLUCIONES</b>
                </span>
              }
            >
              <Menu.Item key="soluciones-0" style={{ textTransform: 'uppercase' }}>
                <Link to="/nueva-construccion">Nueva Construcción</Link>
              </Menu.Item>
              <Menu.Item key="soluciones-1" style={{ textTransform: 'uppercase' }}>
                <Link to="/restauracion">Restauración</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="memu-2">
              <Link to="/contacto">
                <b>CONTACTO</b>
              </Link>
            </Menu.Item>
          </Menu>
          <HeaderLogo>
            <HeaderLogoA href="/">
              <GatsbyImage
                image={data.sto.childImageSharp.gatsbyImageData}
                alt="Logo-Sto"
                style={{ display: 'inline-flex' }}
              />
            </HeaderLogoA>
          </HeaderLogo>
        </HeaderBottom>
      </Header>
    </nav>
  );
});

export default MyHeader;
